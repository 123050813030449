@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Montserrat", sans-serif;
}

.bgGradient {
  background: linear-gradient(221.69deg, #e91c24 20.47%, #9f3237 76.45%);
  border-radius: 16px;
  gap: 11px;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #e91c24;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #e91c24;
}
.card-bg {
  border-radius: 20px;
}
.customer-card {
  background: rgba(217, 217, 217, 0.17);
  padding: 23px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.my-contact {
  border-radius: 13px;
  background: #fff;
  box-shadow: 5px 3px 4px 3px rgba(0, 0, 0, 0.06);
  /* max-width: 1156px; */

}
