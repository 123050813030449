.wrapper {
  /* background-image: url("../components//capisave/Isolation.png"); */
  background-repeat: no-repeat;
  background-color: #09112C;
  background-size: cover;
  background-position: center;
  /* position: relative; */
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #09112C;
  height: 100%;
  width: 100%;
}

.flexx{
  display: flex;
  align-items: center;
  gap: 20px;

}

.flexx-span{
  font-size: 18px;
font-weight: bold;
}
