.swiper {
  padding-top: 100px;
}
.swiper-button-prev,
.swiper-button-next {
  color: #000 !important;
  transform: scale(0.5) !important;
  width: 50px !important;
  font-weight: 700;
  transition: 0.2s;
  display: grid;
  place-items: center;
  margin-top: 2px;
}

.swiper-button-prev {
  left: 83%;
  top: 50px;
  z-index: 99;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url(" https://res.cloudinary.com/dzcpsb02f/image/upload/v1680967251/capival/leftArrow_uqnqi6.png");
}
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next {
  right: 13px;
  top: 50px;
  z-index: 99;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dzcpsb02f/image/upload/v1680967020/capival/rightaArrow_uotyu4.png");
}
.swiper-button-next::after {
  display: none;
}

@media (max-width: 1046px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}
