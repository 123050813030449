.swiper {
  width: 95%;
  height: 100%;
  margin: auto;
  overflow: hidden;
}

.swiper-pagination {
  text-align: center !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
}
.mySwiper {
  overflow: hidden !important;
}
